var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"选择组织架构","okText":"确认选择","cancelText":"取消","width":900,"centered":true,"closable":false,"maskClosable":false,"visible":_vm.visible},on:{"ok":_vm.btnSubmit,"cancel":_vm.btnCancel}},[_c('div',{staticClass:"rate-manage-container"},[_c('div',{staticClass:"container-toolbar"},[_c('div',{staticClass:"toolbar-item"},[_c('label',{staticClass:"toolbar-label"},[_vm._v("组织架构")]),_c('a-input',{staticClass:"toolbar-input",attrs:{"allowClear":""},model:{value:(_vm.formSearch.orgName),callback:function ($$v) {_vm.$set(_vm.formSearch, "orgName", $$v)},expression:"formSearch.orgName"}})],1),_c('div',{staticClass:"toolbar-item"},[_c('label',{staticClass:"toolbar-label"},[_vm._v("层级")]),_c('a-select',{staticClass:"toolbar-select",attrs:{"placeholder":"全部","allowClear":""},model:{value:(_vm.formSearch.orgLevel),callback:function ($$v) {_vm.$set(_vm.formSearch, "orgLevel", $$v)},expression:"formSearch.orgLevel"}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":""}},[_vm._v(" 全部 ")]),_vm._l((_vm.enumOrganStructureLevel),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)],1),_c('div',{staticClass:"toolbar-item"},[_c('a-button',{staticClass:"toolbar-button",attrs:{"type":"primary"},on:{"click":_vm.btnQuery}},[_vm._v("查询")]),_c('a-button',{staticClass:"toolbar-button",on:{"click":_vm.btnReset}},[_vm._v("重置")])],1)]),_c('div',{staticClass:"container-content"},[_c('a-table',{attrs:{"locale":{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' },"row-selection":{
					selectedRowKeys: _vm.selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						this.selectedRowKeys = selectedRowKeys
						this.selectedRows = selectedRows
					}
				},"customRow":(record, index) => {
						return {
							on: {
								click: () => {
									if (
										this.selectedRowKeys.findIndex(
											(item) => item == record.id
										) == -1
									) {
										this.selectedRowKeys.push(record.id)
										this.selectedRows.push(record)
									} else {
										this.selectedRowKeys.splice(
											this.selectedRowKeys.findIndex(
												(item) => item == record.id
											),
											1
										)
										this.selectedRows.splice(
											this.selectedRows.findIndex(
												(item) => item.id == record.id
											),
											1
										)
									}
								}
							}
						}
					},"scroll":{ y: 450 },"columns":[
					{
						title: '组织架构',
						dataIndex: 'orgName',
						align: 'center',
						ellipsis: true
					},
					{
						title: '层级',
						dataIndex: 'type',
						align: 'center',
						ellipsis: true,
						scopedSlots: { customRender: 'type' }
					}
				],"data-source":_vm.tableData,"pagination":false},scopedSlots:_vm._u([{key:"type",fn:function(text){return [_vm._v(" "+_vm._s(_vm.getLabelByValue(_vm.enumOrganStructureLevel, text))+" ")]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }