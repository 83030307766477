<script>
	import RuleTemplate from './RuleTemplate'
	import { enumApplicableLevel, getLabelByValue } from '@/utils/EnumUtils'
	// import CommonApi from '@/api/CommonApi'
	// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
	import { Debounce } from '@/utils/OperateUtils'
	export default {
		name: 'AlarmRuleEdit',
		components: {
			RuleTemplate
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			formData: {
				type: Object
			}
		},
		data() {
			return {
				form: this.$form.createForm(this),
				enumAlarmType: [],
				enumAlarmGrade: [],
				enumApplicableLevel: enumApplicableLevel,
				getLabelByValue: getLabelByValue,
				editLoading: false
			}
		},
		mounted() {
			this.getAlarmTypeList()
			this.getAlarmGradeList()
		},
		watch: {
			visible(nVal) {
				this.editLoading = false
				if (nVal && this.formData) {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							warnName: this.formData.warnName,
							warnNotify: this.formData.warnNotify,
							warnType: this.formData.warnType,
							warnLevel: this.formData.warnLevel
						})
					})
				} else {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							warnName: '',
							warnNotify: '',
							warnType: '',
							warnLevel: ''
						})
					})
				}
			}
		},
		methods: {
			getAlarmTypeList() {
				this.$api.getDictList('WARN_TYPE').then((response) => {
					if (response.code == 200) {
						this.enumAlarmType = response.data.sort((a, b) => a.sort - b.sort)
					}
				})
			},
			getAlarmGradeList() {
				this.$api.getDictList('WARN_LEVEL').then((response) => {
					if (response.code == 200) {
						this.enumAlarmGrade = response.data.sort((a, b) => a.sort - b.sort)
					}
				})
			},
			btnConfirm: Debounce(function() {
				this.form.validateFields((errors, values) => {
					if (!errors) {
						this.editLoading = true
						this.$api.updateAlarmRule({
							id: this.formData.id,
							warnName: values.warnName,
							warnNotify: values.warnNotify,
							warnType: values.warnType,
							warnLevel: values.warnLevel
						})
							.then((response) => {
								if (response.code == 200) {
									this.$message.success('保存成功')
									this.$emit('ok')
								} else {
									response.msg && this.$message.error(response.msg)
								}
								this.editLoading = false
							})
							.catch((err) => {
								err.message && this.$message.error(err.message)
								this.editLoading = false
							})
					} else {
						return false
					}
				})
			}),
			btnCancel() {
				this.$emit('cancel')
			}
		}
	}
</script>

<template>
	<a-drawer
		class="edit-drawer"
		title="编辑预警规则"
		:width="480"
		:closable="false"
		:maskClosable="true"
		:visible="visible"
		@close="btnCancel"
	>
		<a-form
			:form="form"
			:colon="false"
			:labelCol="{ span: 6 }"
			:wrapperCol="{ span: 18 }"
			:labelAlign="'right'"
		>
			<a-form-item label="预警名称">
				<a-input
					placeholder="请输入预警名称"
					v-decorator="[
						'warnName',
						{
							rules: [{ required: true, message: '请输入预警名称' }]
						}
					]"
				/>
			</a-form-item>
			<a-form-item label="碰撞预警规则">
				<RuleTemplate
					v-if="formData"
					:ruleTemplate="formData.warnRule"
					:ruleItems="formData.ruleItems"
					:ruleValue="formData.defaultValue"
				></RuleTemplate>
			</a-form-item>
			<a-form-item label="研判通知">
				<a-input
					placeholder="请输入研判通知"
					v-decorator="[
						'warnNotify',
						{
							rules: [{ required: true, message: '请输入研判通知' }]
						}
					]"
				/>
			</a-form-item>
			<a-form-item label="预警类型">
				<a-select
					placeholder="请选择"
					v-decorator="[
						'warnType',
						{
							rules: [{ required: true, message: '请选择预警类型' }]
						}
					]"
				>
					<a-icon slot="suffixIcon" type="caret-down" />
					<a-select-option value="">
						请选择
					</a-select-option>
					<a-select-option
						v-for="item in enumAlarmType"
						:value="item.id"
						:key="item.id"
					>
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item label="预警等级">
				<a-select
					placeholder="请选择"
					v-decorator="[
						'warnLevel',
						{
							rules: [{ required: true, message: '请选择预警等级' }]
						}
					]"
				>
					<a-icon slot="suffixIcon" type="caret-down" />
					<a-select-option value="">
						请选择
					</a-select-option>
					<a-select-option
						v-for="item in enumAlarmGrade"
						:value="item.id"
						:key="item.id"
					>
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item label="适用层级">
				<label class="form-label" v-if="formData">
					{{ getLabelByValue(enumApplicableLevel, formData.applyHierarchical) }}
				</label>
			</a-form-item>
		</a-form>
		<div class="close-button">
			<i class="ali-icon ali-icon-action-close" @click="btnCancel"></i>
		</div>
		<div class="submit-button">
			<a-button :style="{ marginRight: '15px' }" @click="btnCancel">
				关闭
			</a-button>
			<a-button :loading="editLoading" type="primary" @click="btnConfirm">
				保存
			</a-button>
		</div>
	</a-drawer>
</template>

<style lang="scss" scoped>
	.edit-drawer {
		/deep/ {
			.ant-drawer-body {
				max-height: calc(100% - 116px);
			}
		}
	}
</style>
