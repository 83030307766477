<script>
	// import IconHistoryBack from '@/components/IconHistoryBack'
	import AlarmRuleIssue from '@/views/collision-alarm/alarm-rule/AlarmRuleIssue'
	import AlarmRuleEdit from '@/views/collision-alarm/alarm-rule/AlarmRuleEdit'
	import {
		enumApplicableLevel,
		enumInsertStatus,
		getLabelByValue
	} from '@/utils/EnumUtils'
	// import CommonApi from '@/api/CommonApi'
	// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
	import DataTransformUtils from '@/utils/DataTransformUtils'
	import { Debounce } from '@/utils/OperateUtils'
	export default {
		name: 'AlarmRuleBase',
		components: {
			// IconHistoryBack,
			AlarmRuleIssue,
			AlarmRuleEdit
		},
		data() {
			return {
				tableHeight: 200,
				enumAlarmType: [],
				enumAlarmGrade: [],
				enumApplicableLevel: enumApplicableLevel,
				enumInsertStatus: enumInsertStatus,
				getLabelByValue: getLabelByValue,
				formSearch: {
					warnName: '',
					warnType: '',
					warnLevel: '',
					applyHierarchical: '',
					added: ''
				},
				searchData: {
					warnName: '',
					warnType: '',
					warnLevel: '',
					applyHierarchical: '',
					added: '',
					current: 1,
					size: 10
				},
				tableLoading: false,
				tableData: [],
				total: 0,
				issueVisible: false,
				editVisible: false,
				selectRow: null
			}
		},
		mounted() {
			this.getAlarmTypeList()
			this.getAlarmGradeList()
			this.getAlarmRuleBaseList()
			this.tableHeight = this.$refs.tableContainer.clientHeight - 98
			window.addEventListener('resize', this.tableResize)
		},
		destroyed() {
			window.removeEventListener('resize', this.tableResize)
		},
		computed: {
			userOrganType() {
				return this.$store.state.userInfo.userOrganType
			}
		},
		methods: {
			getAlarmTypeList() {
				this.$api.getDictList('WARN_TYPE').then((response) => {
					if (response.code == 200) {
						this.enumAlarmType = response.data.sort((a, b) => a.sort - b.sort)
					}
				})
			},
			getAlarmGradeList() {
				this.$api.getDictList('WARN_LEVEL').then((response) => {
					if (response.code == 200) {
						this.enumAlarmGrade = response.data.sort((a, b) => a.sort - b.sort)
					}
				})
			},
			getAlarmRuleBaseList() {
				this.tableLoading = true
				this.$api.getAlarmRuleBaseList(this.searchData)
					.then((response) => {
						if (response.code == 200) {
							this.tableData = response.data.records.map((item, index) => {
								item.key =
									(this.searchData.current - 1) * this.searchData.size +
									index +
									1
								let templateArr = DataTransformUtils.convertRuleTemplate(
									item.warnRule
								).map((titem) => {
									if (titem.type == 'string') {
										return titem.value
									} else {
										return '<span style="color: #529aff; font-weight: bold;margin: 0 2px;">__</span>'
									}
								})
								item.warnRuleTemplate = templateArr.join('')
								return item
							})
							this.total = response.data.total
						} else {
							response.msg && this.$message.error(response.msg)
						}
						this.tableLoading = false
					})
					.catch((err) => {
						err.message && this.$message.error(err.message)
						this.tableLoading = false
					})
			},
			pageNumChange(current) {
				this.searchData.current = current
				this.getAlarmRuleBaseList()
			},
			pageSizeChange(current, size) {
				this.searchData.current = 1
				this.searchData.size = size
				this.getAlarmRuleBaseList()
			},
			btnQuery: Debounce(function() {
				this.searchData.warnName = this.formSearch.warnName
				this.searchData.warnType = this.formSearch.warnType
				this.searchData.warnLevel = this.formSearch.warnLevel
				this.searchData.applyHierarchical = this.formSearch.applyHierarchical
				this.searchData.added = this.formSearch.added
				this.searchData.current = 1
				this.getAlarmRuleBaseList()
			}),
			btnReset: Debounce(function() {
				this.searchData.warnName = this.formSearch.warnName = ''
				this.searchData.warnType = this.formSearch.warnType = ''
				this.searchData.warnLevel = this.formSearch.warnLevel = ''
				this.searchData.applyHierarchical = this.formSearch.applyHierarchical =
					''
				this.searchData.added = this.formSearch.added = ''
				this.searchData.current = 1
				this.getAlarmRuleBaseList()
			}),
			// 添加 / 移除
			enableModal(record) {
				this.selectRow = record
			},
			enableOk: Debounce(function() {
				if (this.selectRow.added) {
					this.$api.removeAlarmRule({
						id: this.selectRow.id
					})
						.then((response) => {
							if (response.code == 200) {
								this.$message.success('移除成功')
								this.getAlarmRuleBaseList()
							} else {
								response.msg && this.$message.error(response.msg)
							}
						})
						.catch((err) => {
							err.message && this.$message.error(err.message)
						})
				} else {
					this.$api.insertAlarmRule2({
						id: this.selectRow.id
					})
						.then((response) => {
							if (response.code == 200) {
								this.$message.success('添加成功')
								this.getAlarmRuleBaseList()
							} else {
								response.msg && this.$message.error(response.msg)
							}
						})
						.catch((err) => {
							err.message && this.$message.error(err.message)
						})
				}
			}),
			issueModal(record) {
				this.selectRow = record
				this.issueVisible = true
			},
			issueOk() {
				this.issueVisible = false
				this.getAlarmRuleBaseList()
			},
			issueCancel() {
				this.issueVisible = false
			},
			editModal(record) {
				this.selectRow = record
				this.editVisible = true
			},
			editOk() {
				this.editVisible = false
				this.getAlarmRuleBaseList()
			},
			editCancel() {
				this.editVisible = false
			},
			tableResize() {
				this.tableHeight = this.$refs.tableContainer.clientHeight - 98
			}
		}
	}
</script>

<template>
	<div class="rate-manage-container">
		<!-- <a-breadcrumb class="container-breadcrumb">
			<a-breadcrumb-item>
				<IconHistoryBack />
			</a-breadcrumb-item>
			<a-breadcrumb-item>预警规则</a-breadcrumb-item>
			<a-breadcrumb-item>预警规则库</a-breadcrumb-item>
		</a-breadcrumb> -->
		<div class="container-toolbar search-container" @keyup.enter="btnQuery">
			<div class="toolbar-item">
				<label class="toolbar-label">预警名称</label>
				<a-input
					class="toolbar-input"
					v-model="formSearch.warnName"
					allowClear
				/>
			</div>
			<div class="toolbar-item">
				<label class="toolbar-label">预警类型</label>
				<a-select
					class="toolbar-select"
					placeholder="全部"
					v-model="formSearch.warnType"
					allowClear
				>
					<a-icon slot="suffixIcon" type="caret-down" />
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option
						v-for="item in enumAlarmType"
						:value="item.id"
						:key="item.id"
					>
						{{ item.name }}
					</a-select-option>
				</a-select>
			</div>
			<div class="toolbar-item">
				<label class="toolbar-label">预警等级</label>
				<a-select
					class="toolbar-select"
					placeholder="全部"
					v-model="formSearch.warnLevel"
					allowClear
				>
					<a-icon slot="suffixIcon" type="caret-down" />
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option
						v-for="item in enumAlarmGrade"
						:value="item.id"
						:key="item.id"
					>
						{{ item.name }}
					</a-select-option>
				</a-select>
			</div>
			<div class="toolbar-item">
				<label class="toolbar-label">适用层级</label>
				<a-select
					class="toolbar-select"
					placeholder="全部"
					v-model="formSearch.applyHierarchical"
					allowClear
				>
					<a-icon slot="suffixIcon" type="caret-down" />
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option
						v-for="item in enumApplicableLevel"
						:value="item.value"
						:key="item.value"
					>
						{{ item.label }}
					</a-select-option>
				</a-select>
			</div>
			<div class="toolbar-item">
				<label class="toolbar-label">添加状态</label>
				<a-select
					class="toolbar-select"
					placeholder="全部"
					v-model="formSearch.added"
					allowClear
				>
					<a-icon slot="suffixIcon" type="caret-down" />
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option
						v-for="item in enumInsertStatus"
						:value="item.value"
						:key="item.value"
					>
						{{ item.label }}
					</a-select-option>
				</a-select>
			</div>
			<div class="toolbar-item">
				<a-button type="primary" @click="btnQuery" class="m-r-8">
					<a-icon type="search" />查询
				</a-button>
				<a-button @click="btnReset" class="m-r-8">
					<a-icon type="reload" />重置
				</a-button>
			</div>
		</div>
		<div class="container-content" ref="tableContainer">
			<a-table
				class="alarm-table"
				:locale="{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' }"
				:scroll="{ y: tableHeight }"
				:columns="[
					{
						title: '序号',
						dataIndex: 'key',
						width: 90
					},
					{
						title: '预警名称',
						dataIndex: 'warnName',
						width: 180,
						ellipsis: true
					},
					{
						title: '碰撞预警规则',
						dataIndex: 'warnRuleTemplate',
						width: '65%',
						scopedSlots: { customRender: 'warnRuleTemplate' }
					},
					{
						title: '研判通知',
						dataIndex: 'warnNotify',
						width: '35%'
					},
					{
						title: '预警类型',
						dataIndex: 'typeName',
						align: 'center',
						width: 100,
						ellipsis: true
					},
					{
						title: '预警等级',
						dataIndex: 'levelName',
						align: 'center',
						width: 100,
						ellipsis: true
					},
					{
						title: '适用层级',
						dataIndex: 'applyHierarchical',
						align: 'center',
						width: 100,
						ellipsis: true,
						scopedSlots: { customRender: 'applyHierarchical' }
					},
					{
						title: '添加状态',
						dataIndex: 'added',
						align: 'center',
						width: 100,
						ellipsis: true,
						scopedSlots: { customRender: 'added' }
					},
					{
						title: '操作',
						dataIndex: 'operation',
						align: 'center',
						width: 160,
						scopedSlots: { customRender: 'operation' }
					}
				]"
				:data-source="tableData"
				:pagination="false"
			>
				<span slot="warnRuleTemplate" slot-scope="text" v-html="text"></span>
				<template slot="applyHierarchical" slot-scope="text">
					{{ getLabelByValue(enumApplicableLevel, text) }}
				</template>
				<template slot="added" slot-scope="text">
					{{ getLabelByValue(enumInsertStatus, text) }}
				</template>
				<router-link
					slot="issueCount"
					slot-scope="text, record"
					:to="{
						name: 'collision-alarm-AlarmRule',
						params: {
							baseId: record.id,
							isIssue: text ? '1' : '0'
						}
					}"
				>
					{{ text }}
				</router-link>
				<template slot="operation" slot-scope="text, record">
					<div class="table-operation">
						<a-popconfirm
							:title="
								`确认要${record.added ? '移除' : '添加'}该条预警规则数据吗？`
							"
							placement="topRight"
							ok-text="确定"
							cancel-text="取消"
							@confirm="enableOk"
						>
							<a href="javascript:void(0)" @click="enableModal(record)">
								{{ record.added ? '移除' : '添加' }}
							</a>
						</a-popconfirm>
						<template v-if="userOrganType == 0">
							<label class="opeartion-line"></label>
							<a href="javascript:void(0)" @click="issueModal(record)">
								下发
							</a>
							<label class="opeartion-line"></label>
							<a href="javascript:void(0)" @click="editModal(record)">
								编辑
							</a>
						</template>
					</div>
				</template>
			</a-table>
		</div>
		<div class="content-page pagination-container" v-if="total">
			<a-pagination
				:showQuickJumper="true"
				:showSizeChanger="true"
				:current="searchData.current"
				:pageSize="searchData.size"
				:total="total"
				:show-total="(total) => `共${total}条`"
				@change="pageNumChange"
				@showSizeChange="pageSizeChange"
			/>
		</div>
		<AlarmRuleIssue
			:visible="issueVisible"
			:formData="selectRow"
			@ok="issueOk"
			@cancel="issueCancel"
		></AlarmRuleIssue>
		<AlarmRuleEdit
			:visible="editVisible"
			:formData="selectRow"
			@ok="editOk"
			@cancel="editCancel"
		></AlarmRuleEdit>
	</div>
</template>

<style lang="scss" scoped>
	.alarm-table {
		/deep/ {
			.ant-table-tbody > tr > td {
				height: 96px;
			}
		}
	}
</style>
