var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{staticClass:"edit-drawer",attrs:{"title":"编辑预警规则","width":480,"closable":false,"maskClosable":true,"visible":_vm.visible},on:{"close":_vm.btnCancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false,"labelCol":{ span: 6 },"wrapperCol":{ span: 18 },"labelAlign":'right'}},[_c('a-form-item',{attrs:{"label":"预警名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'warnName',
					{
						rules: [{ required: true, message: '请输入预警名称' }]
					}
				]),expression:"[\n\t\t\t\t\t'warnName',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [{ required: true, message: '请输入预警名称' }]\n\t\t\t\t\t}\n\t\t\t\t]"}],attrs:{"placeholder":"请输入预警名称"}})],1),_c('a-form-item',{attrs:{"label":"碰撞预警规则"}},[(_vm.formData)?_c('RuleTemplate',{attrs:{"ruleTemplate":_vm.formData.warnRule,"ruleItems":_vm.formData.ruleItems,"ruleValue":_vm.formData.defaultValue}}):_vm._e()],1),_c('a-form-item',{attrs:{"label":"研判通知"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'warnNotify',
					{
						rules: [{ required: true, message: '请输入研判通知' }]
					}
				]),expression:"[\n\t\t\t\t\t'warnNotify',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [{ required: true, message: '请输入研判通知' }]\n\t\t\t\t\t}\n\t\t\t\t]"}],attrs:{"placeholder":"请输入研判通知"}})],1),_c('a-form-item',{attrs:{"label":"预警类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'warnType',
					{
						rules: [{ required: true, message: '请选择预警类型' }]
					}
				]),expression:"[\n\t\t\t\t\t'warnType',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [{ required: true, message: '请选择预警类型' }]\n\t\t\t\t\t}\n\t\t\t\t]"}],attrs:{"placeholder":"请选择"}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":""}},[_vm._v(" 请选择 ")]),_vm._l((_vm.enumAlarmType),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1),_c('a-form-item',{attrs:{"label":"预警等级"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'warnLevel',
					{
						rules: [{ required: true, message: '请选择预警等级' }]
					}
				]),expression:"[\n\t\t\t\t\t'warnLevel',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [{ required: true, message: '请选择预警等级' }]\n\t\t\t\t\t}\n\t\t\t\t]"}],attrs:{"placeholder":"请选择"}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":""}},[_vm._v(" 请选择 ")]),_vm._l((_vm.enumAlarmGrade),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1),_c('a-form-item',{attrs:{"label":"适用层级"}},[(_vm.formData)?_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.getLabelByValue(_vm.enumApplicableLevel, _vm.formData.applyHierarchical))+" ")]):_vm._e()])],1),_c('div',{staticClass:"close-button"},[_c('i',{staticClass:"ali-icon ali-icon-action-close",on:{"click":_vm.btnCancel}})]),_c('div',{staticClass:"submit-button"},[_c('a-button',{style:({ marginRight: '15px' }),on:{"click":_vm.btnCancel}},[_vm._v(" 关闭 ")]),_c('a-button',{attrs:{"loading":_vm.editLoading,"type":"primary"},on:{"click":_vm.btnConfirm}},[_vm._v(" 保存 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }