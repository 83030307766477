<script>
	import OrganSelect from '@/components/OrganSelect'
	import { enumOrganStructureLevel, getLabelByValue } from '@/utils/EnumUtils'
	// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
	import DataTransformUtils from '@/utils/DataTransformUtils'
	import { Debounce } from '@/utils/OperateUtils'
	export default {
		name: 'AlarmRuleIssue',
		components: {
			OrganSelect
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			formData: {
				type: Object
			}
		},
		data() {
			return {
				enumOrganStructureLevel: enumOrganStructureLevel.filter(
					(item) => item.value != 0
				),
				getLabelByValue: getLabelByValue,
				issueOrigin: [],
				issueData: [],
				selectVisible: false,
				editLoading: false
			}
		},
		watch: {
			visible(nVal) {
				this.editLoading = false
				if (nVal) {
					this.getAlarmRuleIssueDetail()
				}
			}
		},
		methods: {
			getAlarmRuleIssueDetail() {
				this.$api.getAlarmRuleIssueDetail(this.formData.id).then(
					(response) => {
						if (response.code == 200) {
							this.issueOrigin = response.data.orgs.map((item, index) => {
								item.key = index + 1
								return item
							})
							this.issueData = DataTransformUtils.deepClone(this.issueOrigin)
						}
					}
				)
			},
			selectOk(selectData) {
				this.selectVisible = false
				this.issueData = [...this.issueData, ...selectData].map(
					(item, index) => {
						item.key = index + 1
						return item
					}
				)
			},
			selectCancel() {
				this.selectVisible = false
			},
			deleteIssueItem(record) {
				this.issueData.splice(
					this.issueData.findIndex((item) => item.id === record.id),
					1
				)
				this.issueData = this.issueData.map((item, index) => {
					let obj = DataTransformUtils.deepClone(item)
					obj.key = index + 1
					return obj
				})
			},
			btnConfirm: Debounce(function() {
				if (this.issueData.length) {
					let issueOrgIds = this.issueData.filter(
						(item) => !this.issueOrigin.some((ele) => ele.id == item.id)
					)
					let removeOrgIds = this.issueOrigin.filter(
						(item) => !this.issueData.some((ele) => ele.id == item.id)
					)
					if (issueOrgIds.length || removeOrgIds.length) {
						this.editLoading = true
						this.$api.issueAlarmRule({
							id: this.formData.id,
							issueOrgIds: issueOrgIds.map((item) => item.id).toString(),
							removeOrgIds: removeOrgIds.map((item) => item.id).toString()
						})
							.then((response) => {
								if (response.code == 200) {
									this.$message.success('下发成功')
									this.$emit('ok')
								} else {
									response.msg && this.$message.error(response.msg)
								}
								this.editLoading = false
							})
							.catch((err) => {
								err.message && this.$message.error(err.message)
								this.editLoading = false
							})
					} else {
						this.$message.warning('下发组织无变化')
					}
				} else {
					this.$message.warning('请选择下发组织')
				}
			}),
			btnCancel() {
				this.$emit('cancel')
			}
		}
	}
</script>

<template>
	<a-drawer
		class="edit-drawer"
		title="下发预警规则"
		:width="480"
		:closable="false"
		:maskClosable="true"
		:visible="visible"
		@close="btnCancel"
	>
		<a-form
			:colon="false"
			:labelCol="{ span: 6 }"
			:wrapperCol="{ span: 18 }"
			:labelAlign="'right'"
		>
			<a-form-item label="预警名称">
				<label class="form-label" v-if="formData">{{
					formData.warnName
				}}</label>
			</a-form-item>
			<a-form-item label="下发组织架构" required>
				<a-icon
					class="add-organ"
					theme="filled"
					type="plus-circle"
					@click="selectVisible = true"
				/>
			</a-form-item>
			<a-form-item :wrapperCol="{ span: 24 }">
				<a-table
					:columns="[
						{
							title: '序号',
							dataIndex: 'key',
							align: 'center',
							width: 72
						},
						{
							title: '组织架构',
							dataIndex: 'orgName',
							align: 'center',
							ellipsis: true
						},
						{
							title: '层级',
							dataIndex: 'type',
							align: 'center',
							ellipsis: true,
							scopedSlots: { customRender: 'type' }
						},
						{
							title: '操作',
							dataIndex: 'operation',
							align: 'center',
							width: 72,
							scopedSlots: { customRender: 'operation' }
						}
					]"
					:data-source="issueData"
					:pagination="false"
				>
					<template slot="type" slot-scope="text">
						{{ getLabelByValue(enumOrganStructureLevel, text) }}
					</template>
					<a
						slot="operation"
						slot-scope="text, record"
						href="javascript:void(0)"
						@click="deleteIssueItem(record)"
					>
						删除
					</a>
				</a-table>
			</a-form-item>
		</a-form>
		<div class="close-button">
			<i class="ali-icon ali-icon-action-close" @click="btnCancel"></i>
		</div>
		<div class="submit-button">
			<a-button :style="{ marginRight: '15px' }" @click="btnCancel">
				关闭
			</a-button>
			<a-button :loading="editLoading" type="primary" @click="btnConfirm">
				保存
			</a-button>
		</div>
		<OrganSelect
			:visible="selectVisible"
			:selectedValue="issueData"
			@ok="selectOk"
			@cancel="selectCancel"
		></OrganSelect>
	</a-drawer>
</template>

<style lang="scss" scoped>
	.edit-drawer {
		/deep/ {
			.ant-drawer-body {
				max-height: calc(100% - 116px);
			}
		}
	}
	.add-organ {
		color: #4b80cb;
		&:hover {
			cursor: pointer;
			color: #ffffff;
		}
	}
</style>
