<script>
	import { enumOrganStructureLevel, getLabelByValue } from '@/utils/EnumUtils'
	import DataTransformUtils from '@/utils/DataTransformUtils'
	export default {
		name: 'OrganSelect',
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			selectedValue: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				organStructure: [],
				enumOrganStructureLevel: enumOrganStructureLevel.filter(
					(item) => item.value != 0
				),
				getLabelByValue: getLabelByValue,
				formSearch: {
					orgName: '',
					orgLevel: ''
				},
				searchData: {
					orgName: '',
					orgLevel: ''
				},
				tableLoading: false,
				tableData: [],
				selectedRowKeys: [],
				selectedRows: []
			}
		},
		mounted() {
			this.getOrganStructure()
		},
		watch: {
			visible(nVal) {
				if (nVal) {
					this.selectedRowKeys = []
					this.selectedRows = []
					this.tableData = []
					this.searchData.orgName = this.formSearch.orgName = ''
					this.searchData.orgLevel = this.formSearch.orgLevel = ''
					this.getOrganStructure()
				}
			}
		},
		methods: {
			getOrganStructure() {
				this.tableLoading = true
				this.$api.getOrganStructure()
					.then((response) => {
						if (response.code == 200) {
							let selectedArr = this.selectedValue.map((item) => item.id)
							this.organStructure = response.data
								.filter(
									(item) => item.type != 0 && !selectedArr.includes(item.id)
								)
								.map((item) => {
									item.key = item.id
									return item
								})
							this.tableData = DataTransformUtils.deepClone(this.organStructure)
						}
						this.tableLoading = false
					})
					.catch(() => {
						this.tableLoading = false
					})
			},
			btnQuery() {
				this.selectedRowKeys = []
				this.selectedRows = []
				this.searchData.orgName = this.formSearch.orgName
				this.searchData.orgLevel = this.formSearch.orgLevel
				let arr = DataTransformUtils.deepClone(this.organStructure)
				if (this.searchData.orgName) {
					arr = arr.filter(
						(item) => item.name.indexOf(this.searchData.orgName) != -1
					)
				}
				if (this.searchData.orgLevel) {
					arr = arr.filter((item) => item.type == this.searchData.orgLevel)
				}
				this.tableData = arr
			},
			btnReset() {
				this.selectedRowKeys = []
				this.selectedRows = []
				this.searchData.orgName = this.formSearch.orgName = ''
				this.searchData.orgLevel = this.formSearch.orgLevel = ''
				this.tableData = DataTransformUtils.deepClone(this.organStructure)
			},
			btnSubmit() {
				this.$emit('ok', this.selectedRows)
			},
			btnCancel() {
				this.$emit('cancel')
			}
		}
	}
</script>

<template>
	<a-modal
		title="选择组织架构"
		okText="确认选择"
		cancelText="取消"
		:width="900"
		:centered="true"
		:closable="false"
		:maskClosable="false"
		:visible="visible"
		@ok="btnSubmit"
		@cancel="btnCancel"
	>
		<div class="rate-manage-container">
			<div class="container-toolbar">
				<div class="toolbar-item">
					<label class="toolbar-label">组织架构</label>
					<a-input
						class="toolbar-input"
						v-model="formSearch.orgName"
						allowClear
					/>
				</div>
				<div class="toolbar-item">
					<label class="toolbar-label">层级</label>
					<a-select
						class="toolbar-select"
						placeholder="全部"
						v-model="formSearch.orgLevel"
						allowClear
					>
						<a-icon slot="suffixIcon" type="caret-down" />
						<a-select-option value="">
							全部
						</a-select-option>
						<a-select-option
							v-for="item in enumOrganStructureLevel"
							:value="item.value"
							:key="item.value"
						>
							{{ item.label }}
						</a-select-option>
					</a-select>
				</div>
				<div class="toolbar-item">
					<a-button class="toolbar-button" type="primary" @click="btnQuery"
						>查询</a-button
					>
					<a-button class="toolbar-button" @click="btnReset">重置</a-button>
				</div>
			</div>
			<div class="container-content">
				<a-table
					:locale="{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' }"
					:row-selection="{
						selectedRowKeys: selectedRowKeys,
						onChange: (selectedRowKeys, selectedRows) => {
							this.selectedRowKeys = selectedRowKeys
							this.selectedRows = selectedRows
						}
					}"
					:customRow="
						(record, index) => {
							return {
								on: {
									click: () => {
										if (
											this.selectedRowKeys.findIndex(
												(item) => item == record.id
											) == -1
										) {
											this.selectedRowKeys.push(record.id)
											this.selectedRows.push(record)
										} else {
											this.selectedRowKeys.splice(
												this.selectedRowKeys.findIndex(
													(item) => item == record.id
												),
												1
											)
											this.selectedRows.splice(
												this.selectedRows.findIndex(
													(item) => item.id == record.id
												),
												1
											)
										}
									}
								}
							}
						}
					"
					:scroll="{ y: 450 }"
					:columns="[
						{
							title: '组织架构',
							dataIndex: 'orgName',
							align: 'center',
							ellipsis: true
						},
						{
							title: '层级',
							dataIndex: 'type',
							align: 'center',
							ellipsis: true,
							scopedSlots: { customRender: 'type' }
						}
					]"
					:data-source="tableData"
					:pagination="false"
				>
					<template slot="type" slot-scope="text">
						{{ getLabelByValue(enumOrganStructureLevel, text) }}
					</template>
				</a-table>
			</div>
		</div>
	</a-modal>
</template>

<style lang="scss" scoped>
	.container-content {
		height: 450px;
		flex: auto;
	}
</style>
