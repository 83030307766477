var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{staticClass:"edit-drawer",attrs:{"title":"下发预警规则","width":480,"closable":false,"maskClosable":true,"visible":_vm.visible},on:{"close":_vm.btnCancel}},[_c('a-form',{attrs:{"colon":false,"labelCol":{ span: 6 },"wrapperCol":{ span: 18 },"labelAlign":'right'}},[_c('a-form-item',{attrs:{"label":"预警名称"}},[(_vm.formData)?_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.formData.warnName))]):_vm._e()]),_c('a-form-item',{attrs:{"label":"下发组织架构","required":""}},[_c('a-icon',{staticClass:"add-organ",attrs:{"theme":"filled","type":"plus-circle"},on:{"click":function($event){_vm.selectVisible = true}}})],1),_c('a-form-item',{attrs:{"wrapperCol":{ span: 24 }}},[_c('a-table',{attrs:{"columns":[
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center',
						width: 72
					},
					{
						title: '组织架构',
						dataIndex: 'orgName',
						align: 'center',
						ellipsis: true
					},
					{
						title: '层级',
						dataIndex: 'type',
						align: 'center',
						ellipsis: true,
						scopedSlots: { customRender: 'type' }
					},
					{
						title: '操作',
						dataIndex: 'operation',
						align: 'center',
						width: 72,
						scopedSlots: { customRender: 'operation' }
					}
				],"data-source":_vm.issueData,"pagination":false},scopedSlots:_vm._u([{key:"type",fn:function(text){return [_vm._v(" "+_vm._s(_vm.getLabelByValue(_vm.enumOrganStructureLevel, text))+" ")]}},{key:"operation",fn:function(text, record){return _c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.deleteIssueItem(record)}}},[_vm._v(" 删除 ")])}}])})],1)],1),_c('div',{staticClass:"close-button"},[_c('i',{staticClass:"ali-icon ali-icon-action-close",on:{"click":_vm.btnCancel}})]),_c('div',{staticClass:"submit-button"},[_c('a-button',{style:({ marginRight: '15px' }),on:{"click":_vm.btnCancel}},[_vm._v(" 关闭 ")]),_c('a-button',{attrs:{"loading":_vm.editLoading,"type":"primary"},on:{"click":_vm.btnConfirm}},[_vm._v(" 保存 ")])],1),_c('OrganSelect',{attrs:{"visible":_vm.selectVisible,"selectedValue":_vm.issueData},on:{"ok":_vm.selectOk,"cancel":_vm.selectCancel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }